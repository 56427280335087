import React from "react"
import { Link } from "gatsby"
import SEO from '../../components/seo'

import Elements from '../../layouts/elements'



export default () => (

<Elements
	mae='' mae_vi='hidden'
	ato='/elements/0/0-1/'
	url='' douga_vi='none'
	h1='ELEMENTS 必須知識編'
>

<SEO title='ELEMENTS 必須知識編'/>

<div className="products">

<img class="lazyload products_img" alt=""
	  src="https://elvn.imgix.net/products/kaku.jpg?con=40&fit=crop&w=400&auto=format,compress&q=0"
	  data-src="hhttps://elvn.imgix.net/products/kaku.jpg?con=40&fit=crop&w=1927&auto=format&q=35&cs=srgb"
	  data-srcset="https://elvn.imgix.net/products/kaku.jpg?con=40&fit=crop&w=1360&h=1200&auto=format&q=35&cs=srgb 1242w,
				   https://elvn.imgix.net/products/kaku.jpg?con=40&fit=crop&w=1927&auto=format&q=35&cs=srgb 1668w,
				   https://elvn.imgix.net/products/kaku.jpg?con=40&fit=crop&w=1360&h=1200&auto=format&q=35&cs=srgb 1669w"
/>

	<section className="light">

		<p>こんにちは、E.L.V.Nのユーキです。<br/>
		「ELEMENTS 必須知識編」にご参加いただき、ありがとうございます。</p>


		<h2>規約について</h2>

		<p>はじめに、ご利用規約を載せておきます。堅苦しいことは好きではないのですが、何かをする時には書いておかなければなりません。<br/>簡単に言うと、「ちゃんと支払わなきゃダメだよ」「盗用しちゃダメだよ」という当たり前のことが書いてあります。ご一読いただき、ご同意いただける場合のみ本品をご利用ください。ご利用いただいた場合は、同意されたものとみなします。</p>

		<p><Link to="/elements/kiyaku/" className='btn aqua'>利用規約</Link></p>

		<h2>教材の使い方</h2>

		<p>ELEMENTSシリーズは、僕の思考の集大成です。これができれば、巷の複雑でわかりにくいノウハウは、全部ふっとばせます。そして、あらゆる場面で応用が利き放題の、基本の原理が身につきます。</p>

		<p>教材を使うときは、何も見なくても内容を人に説明できるようになるまで、繰り返し見てください。なぜなら、学んでも、使えなければ意味がないからです。</p>


		<h2>鍵は復習</h2>

		<p>脳科学で、学ぶ時に不可欠とされているのが「復習」です。脳は、同じ情報に繰り返し出会うと、「これは重要な情報だ」と認識します。つまり、繰り返して復習するだけで、簡単に定着するのです。</p>

		<h2>最も強力な記憶法</h2>

		<p>さらに、最も強力に記憶に残るのが、「方法記憶」というものです。僕たちは、日本語を忘れません。自転車の乗り方も忘れません。九九も漢字も忘れません。これらは、何年も何ヶ月もかけて、体で習得したものです。体で習得するというのは、「自分で使う」ということです。これは、言い換えると「入ってきた情報を、自分で出す」という行為です。</p>

		<p>つまり、究極の学習方法は、学んだことを自分で使ったり、人に教えたりすることです。これは、僕自身が教える仕事に就いていたからこそ断言しますが、先生は勉強が身についたから教えているのではありません。「教えているから、身についた」のです。</p>

		<h2>方法記憶にするために</h2>

		<p>そういう意味でも、ぜひ「毎日のカリキュラムのメールに返信する」という勉強法を使ってください。その日に覚えた内容の箇条書きでも、その日の感想でも構いません。<br/>返信するということは、その日に頭に入ってきたものを、意識的に「出す」という行為です。「こんな内容だったな」「ここは印象的だったな」と思い出すことで、自分の中で情報が整理され、定着していきます。これこそが、「身につく」プロセスです。</p>

				<p>もちろん、「出す」だけなら、友達や家族を捕まえて話してもいいですし、最悪、独り言でもいいです。だけど、独り言を言うくらいなら、僕にチャチャっとまとめを送ってくれた方が、よほどお互い役に立ちますし、何より僕も嬉しいです。</p>
				<p>また、いただいたご感想や疑問点については、「共有すれば他の方の役にも立つ」と判断できるものを、教材の補足に追加することがあります。基本的には、商品説明にもあったように<strong>個別のご質問にはお答えできません</strong>が（数が多すぎるためです）、とりあえずはダメ元で送ってください。より良い教材づくりにもなりますし、僕も勉強になります。ぜひ、お互いメリットを享受しましょう。</p>

		<p>それでは、教材でお待ちしています。</p>

		<p>E.L.V.N ユーキ</p>

	</section>
</div>


	</Elements>

)
